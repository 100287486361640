// Nodes modules
import './modules/@tc';
import 'classlist-polyfill';
import 'object-fit-polyfill';

import animateScrollTo from 'animated-scroll-to';
//import * as Swiper from 'swiper/dist/js/swiper.js';
import * as Stickyfill from 'stickyfilljs';
import 'jquery-mask-plugin';


window.addEventListener('load', function() {
    //$('input[type="number"]').attr('onkeydown', 'return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode == 9' );

    $('#popup-text a').attr('target','_blank');

    /* RESET BAR */
    var attr;
    attr = $(".webform-progress + .js-form-wrapper").attr('data-drupal-selector');
    //console.log(attr);

    /* FORM RANGE */
    function rangeChange() {
        if($("output").val() != '0km') {
            $("output").addClass('checked');
            $(".start-auto .webform-button--next").addClass('active');
            $(".start-auto .webform-button--next").css('pointer-events', 'all');
        } else {
            $("output").removeClass('checked');
            $(".start-auto .webform-button--next").removeClass('active');
            $(".start-auto .webform-button--next").css('pointer-events', 'none');
        }

        if($("output").hasClass('big')) {
            $("output").removeClass('big');
        }
    }

    $(".form-range")
        .mousedown(function() {
            if(!$("output").hasClass('big')) {
                $("output").addClass('big');
            }
        })

        .mousemove(function() {

        })

        .mouseup(rangeChange);

    /*  BUTTON */
    $('.webform-button--next').click(function(){
        $(this).addClass('ajax-focus');
    });

    $('.webform-button--submit').click(function(){
        $(this).addClass('ajax-focus');
    });

    $('.webform-confirmation__back .button').click(function(){
        $(this).addClass('ajax-focus');
    });

    /* RADIO */
    $('.form-check input.form-radio').each(function (e) {
        $(this).insertBefore($(this).parent());
    });

    $(".js-webform-type-radios").each(function(index) {
        $(this).wrapInner("<div class='form-element-wrapper' />")
    });

    // START AUTO 00
    if(attr == "edit-start-auto") {
        $('.webform-ajax-form-wrapper').addClass('start start-auto');
        $('body').addClass('no-scroll');
        $('body').addClass('grey-bg');
    } else {
        $('.webform-ajax-form-wrapper').removeClass('start-auto');
        //$('body').removeClass('no-scroll');
        //$('body').removeClass('grey-bg');
    }


    // UPDATE 23/03/2022

    var lastDateEncoded = new Date( "01 " + "Jan 2021");
    var dateFrom = lastDateEncoded.getFullYear();
    var dateTo = (new Date).getFullYear();
    var arrDates = Array();
    for(var i = dateFrom; i <= dateTo; i++) arrDates.push(i);


    // START HABITATION 00
    if(attr == "edit-start-habitation") {
        $('.webform-ajax-form-wrapper').addClass('start start-habitation');
        $('body').addClass('no-scroll');
        $('body').addClass('grey-bg');

        function startHabitation() {
            if($('[id^=edit-type-habitation] input').is(':checked')) {
                $(".start-habitation .webform-button--next").addClass('active');
                $(".start-habitation .webform-button--next").css('pointer-events', 'all');
            } else {
                $(".start-habitation .webform-button--next").removeClass('active');
                $(".start-habitation .webform-button--next").css('pointer-events', 'none');
            }
        }

        startHabitation();

        $('[id^=edit-type-habitation] input').click(startHabitation);

    } else {
        $('.webform-ajax-form-wrapper').removeClass('start-habitation');
        //$('body').removeClass('no-scroll');
        //$('body').removeClass('grey-bg');
    }

    if((attr) != "edit-start-habitation" && (attr) != "edit-start-auto") {
        $('.webform-ajax-form-wrapper').removeClass('start');
        $('body').removeClass('no-scroll');
        $('body').removeClass('grey-bg');
    }

    // VALIDATE EMAIL
    var hasError = false;

    function validateMail() {
        $(".error-mail").hide();
        $("[id^=edit-e-mail] + .error-inline").remove();
        hasError = false;
        var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;

        var emailaddressVal = $("[id^=edit-e-mail]").val();
        if(emailaddressVal == '') {
            //$("[id^=edit-e-mail]").after('<span class="error">Veuillez entrer une adresse e-mail</span>');
            hasError = true;
        }

        else if(!emailReg.test(emailaddressVal)) {
            $("[id^=edit-e-mail]").after('<span class="error-inline error-mail">Veuillez entrer une adresse e-mail valide</span>');
            hasError = true;
        }

        //if(hasError == true) { return false; }
    }

    validateMail();

    // HABITATION 01
    if(attr == "edit-vos-informations") {
        $('.webform-ajax-form-wrapper').addClass('habitation-coordonnees');

        $('[id^=edit-e-mail]').on("keydown keyup change",validateMail);

        function requiredInfoHabitation() {
            if ($('[id^=edit-prenom]').val() != ''
                && $('[id^=edit-nom]').val() != ''
                && $('[id^=edit-adresse-address]').val() != ''
                && $('[id^=edit-adresse-postal]').val() != ''
                && $('[id^=edit-adresse-city]').val() != ''
                && $('[id^=edit-e-mail]').val() != ''
                && $('[id^=edit-vous-etes] input').is(':checked')
                && (hasError == false)) {
                $(".habitation-coordonnees .webform-button--next").addClass('active');
                $(".habitation-coordonnees .webform-button--next").css('pointer-events', 'all');
            } else {
                $(".habitation-coordonnees .webform-button--next").removeClass('active');
                $(".habitation-coordonnees .webform-button--next").css('pointer-events', 'none');
            }
        }

        requiredInfoHabitation();

        $(".habitation-coordonnees .required").on("keydown keyup change",requiredInfoHabitation);
        //$(".coord select").on("keydown keyup change",requiredField);
        $("body").on("change", '.habitation-coordonnees .js-webform-radios input',requiredInfoHabitation);

    } else {
        $('.webform-ajax-form-wrapper').removeClass('habitation-coordonnees');
    }

    /* DRUPAL CHANGE */
    Drupal.behaviors.myBehavior = {
        attach: function (context, settings) {

            // VALIDATE NUMBER
            $('input[type="number"]').attr('onkeydown', 'javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 9 || event.keyCode === 37  || event.keyCode === 39 ? true : !isNaN(Number(event.key))');

            $('input[type="tel"].form-tel').attr('onkeydown', 'javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 107 || event.keyCode === 9 || event.keyCode === 37  || event.keyCode === 39 ? true : !isNaN(Number(event.key))');

             $('.lang-fr form').find('[id^=edit-adresse-postal-code]').each(function() {
             $('<input type="tel" data-drupal-selector="edit-adresse-postal-code" data-msg-maxlength="Code postal field has a maximum length of 255." data-msg-required="Code postal is required." type="text" name="adresse[postal_code]" size="60" maxlength="4" placeholder="Code postal" class="form-text required" required="required" aria-required="true" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 9 || event.keyCode === 37  || event.keyCode === 39 ? true : !isNaN(Number(event.key))">').attr({ name: this.name, value: this.value, id: this.id}).insertBefore(this);
             }).remove();

            $('.lang-de form').find('[id^=edit-adresse-postal-code]').each(function() {
                $('<input type="tel" data-drupal-selector="edit-adresse-postal-code" data-msg-maxlength="Code postal field has a maximum length of 255." data-msg-required="Code postal is required." type="text" name="adresse[postal_code]" size="60" maxlength="4" placeholder="PLZ" class="form-text required" required="required" aria-required="true" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 9 || event.keyCode === 37  || event.keyCode === 39 ? true : !isNaN(Number(event.key))">').attr({ name: this.name, value: this.value, id: this.id}).insertBefore(this);
            }).remove();


            var lastDateEncoded = new Date( "01 " + "Jan 2021");
            var dateFrom = lastDateEncoded.getFullYear();
            var dateTo = (new Date).getFullYear();
            var arrDates = Array();
            for(var i = dateFrom; i <= dateTo; i++) arrDates.push(i);

            /*

            $('[id^=edit-adresse-postal-code]').attr('maxlength','4');

            $('[id^=edit-adresse-postal-code]').blur(function() {
                var prefix = 'B-';
                var test = $('[id^=edit-adresse-postal-code]').val();
                if (this.value.substring(0, prefix.length) != prefix){
                    $(this).val(prefix)
                }

                console.log(test);
            });
            */

            // FIELD REQUIRED FOR FILE UPLOAD
            function requiredField() {
                $('.required > label + input').each(function() {
                    if (($(this).val() != '') && $('[id^=edit-annee-construction-year] option:selected').val() != '' && $('.form-item-carburant select option:selected').val() != '') {
                        $(".vehicule .webform-button--next").addClass('active');
                        $(".vehicule .webform-button--next").css('pointer-events', 'all');
                    }

                    else{
                        $(".vehicule .webform-button--next").removeClass('active');
                        $(".vehicule .webform-button--next").css('pointer-events', 'none');
                        return false
                    }
                });


                if($('[id^=edit-annee-construction-year] option:selected').val() != '') {
                    $('[id^=edit-annee-construction-year]').addClass('focus');
                } else {
                    $('[id^=edit-annee-construction-year]').removeClass('focus');
                }

                if($('.form-item-carburant select option:selected').val() != '') {
                    $('.form-item-carburant select').addClass('focus');
                } else {
                    $('.form-item-carburant select').removeClass('focus');
                }
            }

            // FILE UPLOAD
            $('input[type="file"]').change(function() {
                // HIDE IF FILE IS TOO HEAVY
                //console.log(this.files[0].size);

                var checkExist = setInterval(function() {
                    if ($('.file').length) {
                        $('.col-md-4.required').removeClass('required');
                        $('.col-md-4.required-design').removeClass('required-design');
                        $(".webform-button--next").addClass('active');
                        $(".webform-button--next").css('pointer-events', 'all');
                        clearInterval(checkExist);
                    }
                }, 10); // check every 10ms
            });

            $('[id^=edit-upload-carte-grise-remove]').mousedown(function() {
                var checkExist = setInterval(function() {
                    if (!$('.file').length) {
                        $('[id^=edit-container-vehicule] .form-group:not(.form-item-valeur)').addClass('required');
                        $('.js-webform-type-fieldset').addClass('required-design');
                        $(".webform-button--next").removeClass('active');
                        $(".webform-button--next").css('pointer-events', 'none');

                        requiredField();
                        clearInterval(checkExist);
                    }
                }, 10); // check every 10ms
            });

            // STEP
            if (!$('.webform-submission-form').hasClass('once')) {
                $('.webform-submission-form').addClass('once');

                $('#popup-text a').attr('target','_blank');

                $('[id^=edit-e-mail]').on("keydown keyup change",validateMail);

                /* STEP MARKER */
                $('.progress-step:nth-child(2) .progress-marker').html('1');
                $('.progress-step:nth-child(3) .progress-marker').html('2');
                $('.progress-step:nth-child(4) .progress-marker').html('3');

                /* RESET BAR */
                attr = $(".webform-progress + .js-form-wrapper").attr('data-drupal-selector');
                //console.log(attr);

                /* RADIO */
                $('.form-check input.form-radio').each(function (e) {
                    $(this).insertBefore($(this).parent());
                });

                $(".js-webform-type-radios").each(function(index) {
                    $(this).wrapInner("<div class='form-element-wrapper' />")
                });

                /* CHECKBOX */
                if($('.container-step .long-description').length > 0) {
                    $('.long-description .form-check > label').each(function (e) {
                        $(this).append('<span></span>');
                    });
                }

                if($('.js-webform-checkboxes').length > 0) {
                    $('.js-webform-checkboxes .form-check .form-check-label').each(function (e) {
                        $(this).append('<span></span>');
                    });
                }

                /* RADIO */
                if($('.js-webform-type-radios').length > 0) {
                    $('.js-webform-type-radios .form-check .form-check-label').each(function (e) {
                        $(this).append('<span></span>');
                    });
                }

                $('.long-description .form-check .form-check-label').each(function (e) {
                    $('.long-description .description').insertAfter($(this).find('span'));
                });

                /*  BUTTON */
                $('.webform-button--next').click(function(){
                   $(this).addClass('ajax-focus');
                });

                $('.webform-button--submit').click(function(){
                    $(this).addClass('ajax-focus');
                });

                $('.webform-confirmation__back .button').click(function(){
                    $(this).addClass('ajax-focus');
                });

                // START 00
                if(attr == "edit-start-auto") {
                    $('.webform-ajax-form-wrapper').addClass('start start-auto');

                    $('body').addClass('grey-bg');

                    if($("output").val() != '0km') {
                        $("output").addClass('checked');
                        $(".start-auto .webform-button--next").addClass('active');
                        $(".start-auto .webform-button--next").css('pointer-events', 'all');
                    } else {
                        $("output").removeClass('checked');
                        $(".start-auto .webform-button--next").removeClass('active');
                        $(".start-auto .webform-button--next").css('pointer-events', 'none');
                    }

                    $(".form-range")
                        .mousedown(function() {
                            if(!$("output").hasClass('big')) {
                                $("output").addClass('big');
                            }
                        })

                        .mousemove(function() {

                        })

                        .mouseup(function() {
                            if($("output").val() != '0km') {
                                $("output").addClass('checked');
                                $(".start-auto .webform-button--next").addClass('active');
                                $(".start-auto .webform-button--next").css('pointer-events', 'all');
                            } else {
                                $("output").removeClass('checked');
                                $(".start-auto .webform-button--next").removeClass('active');
                                $(".start-auto .webform-button--next").css('pointer-events', 'none');
                            }

                            if($("output").hasClass('big')) {
                                $("output").removeClass('big');
                            }
                        });

                } else {
                    $('.webform-ajax-form-wrapper').removeClass('start-auto');
                    $('body').removeClass('grey-bg');
                }

                // COORD 01
                if(attr == "edit-vos-coordonnees") {
                    $('.webform-ajax-form-wrapper').addClass('coord');
                    $('.webform-ajax-form-wrapper').removeClass('start');
                 

                    // UPDATE 23/03/2022
                    $('.js-form-item-sinistre .webform-element-help').remove();


                    arrDates.forEach(function(val) {                     
                        if($("[id^=edit-permis-year] option:selected").val() != val
                        && $("[id^=edit-permis-year] option:selected").val() != val + 1
                        && $("[id^=edit-permis-year] option:selected").val() != val + 2
                        && $("[id^=edit-permis-year] option:selected").val() != val + 3
                        && $("[id^=edit-permis-year] option:selected").val() != val + 4
                        && $("[id^=edit-permis-year] option:selected").val() != val + 5
                        && $("[id^=edit-permis-year] option:selected").val() != val + 6
                        && $("[id^=edit-permis-year] option:selected").val() != val + 7
                        && $("[id^=edit-permis-year] option:selected").val() != val + 8
                        && $("[id^=edit-permis-year] option:selected").val() != val + 9
                        && $("[id^=edit-permis-year] option:selected").val() != val + 10
                        && $("[id^=edit-permis-year] option:selected").val() != val + 11
                        && $("[id^=edit-permis-year] option:selected").val() != val + 12
                        && $("[id^=edit-permis-year] option:selected").val() != val + 13
                        && $("[id^=edit-permis-year] option:selected").val() != val + 14
                        && $("[id^=edit-permis-year] option:selected").val() != val + 15
                        && $("[id^=edit-permis-year] option:selected").val() != val + 16
                        && $("[id^=edit-permis-year] option:selected").val() != val + 17
                        && $("[id^=edit-permis-year] option:selected").val() != val + 18
                        && $("[id^=edit-permis-year] option:selected").val() != val + 19
                        && $("[id^=edit-permis-year] option:selected").val() != val + 20
                        && $("[id^=edit-permis-year] option:selected").val() != val + 21
                        && $("[id^=edit-permis-year] option:selected").val() != val + 22
                        && $("[id^=edit-permis-year] option:selected").val() != val + 23
                        && $("[id^=edit-permis-year] option:selected").val() != val + 24
                        && $("[id^=edit-permis-year] option:selected").val() != val + 25
                        && $("[id^=edit-permis-year] option:selected").val() != val + 26
                        && $("[id^=edit-permis-year] option:selected").val() != val + 27
                        && $("[id^=edit-permis-year] option:selected").val() != val + 28
                        && $("[id^=edit-permis-year] option:selected").val() != val + 29
                        && $("[id^=edit-permis-year] option:selected").val() != val + 30

                        ) {
                            $("[id^=edit-permis-year] option").eq(1).before($('<option>', {
                                value: val,
                                text: val,
                            }));   
                        }            
                    });
                    

                    arrDates.forEach(function(val) {                     
                        if($("[id^=edit-date-naissance-year] option:selected").val() != val
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 1
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 2
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 3
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 4
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 5
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 6
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 7
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 8
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 9
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 10
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 11
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 12
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 13
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 14
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 15
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 16
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 17
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 18
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 19
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 20
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 21
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 22
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 23
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 24
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 25
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 26
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 27
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 28
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 29
                        && $("[id^=edit-date-naissance-year] option:selected").val() != val + 30

                        ) {
                            $("[id^=edit-date-naissance-year] option").eq(1).before($('<option>', {
                                value: val,
                                text: val,
                            }));   
                        }            
                    });
                    
                    

                    $('[id^=edit-numero-d-agent]').attr('maxlength','4');

                    // REQUIRED FIELD
                    function requiredField() {
                        if ($('[id^=edit-prenom]').val() != ''
                        && $('[id^=edit-nom]').val() != ''
                        && $('[id^= edit-adresse-address]').val() != ''
                        && $('[id^=edit-adresse-postal]').val() != ''
                        && $('[id^=edit-e-mail]').val() != ''
                        && $('.js-form-item-sinistre select option:selected').val() != 'empty_option'
                        && $('[id^=edit-date-naissance-day] option:selected').val() != ''
                        && $('[id^=edit-date-naissance-month] option:selected').val() != ''
                        && $('[id^=edit-date-naissance-year] option:selected').val() != ''
                        && $('[id^=edit-telephone]').val() != ''
                        && $('[id^=edit-permis-year] option:selected').val() != '' && (hasError == false)) {
                            $(".coord .webform-button--next").addClass('active');
                            $(".coord .webform-button--next").css('pointer-events', 'all');
                        } else {
                            $(".coord .webform-button--next").removeClass('active');
                            $(".coord .webform-button--next").css('pointer-events', 'none');
                        }

                        $('.form-item-date-naissance select').each(function (e) {
                            if($(this).val() != '') {
                                $(this).addClass('focus');
                            } else {
                                $(this).removeClass('focus');
                            }
                        });

                        if($('.coord .js-form-item-sinistre select option:selected').val() != 'empty_option') {
                            $('.coord .js-form-item-sinistre select').addClass('focus');

                        } else {
                            $('.coord .js-form-item-sinistre select').removeClass('focus');
                        }

                        if($('.coord .js-form-item-permis select option:selected').val() != '') {
                            $('.coord .js-form-item-permis select').addClass('focus');
                        } else {
                            $('.coord .js-form-item-permis select').removeClass('focus');
                        }
                    }

                    requiredField();

                    $(".coord .required").on("keydown keyup change",requiredField);
                    //$(".coord select").on("keydown keyup change",requiredField);
                    $("body").on("change", '.coord select',requiredField);

                } else {
                    $('.webform-ajax-form-wrapper').removeClass('coord');
                }

                // VEHICULE 02
                if(attr == "edit-votre-vehicule") {
                    $('.webform-ajax-form-wrapper').removeClass('start');
                    $('.webform-ajax-form-wrapper').addClass('vehicule');

                    // UPDATE 23/03/2022
                    arrDates.forEach(function(val) {                     
                        if($("[id^=edit-annee-construction] option:selected").val() != val
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 1
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 2
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 3
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 4
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 5
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 6
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 7
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 8
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 9
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 10
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 11
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 12
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 13
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 14
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 15
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 16
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 17
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 18
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 19
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 20
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 21
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 22
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 23
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 24
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 25
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 26
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 27
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 28
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 29
                        && $("[id^=edit-annee-construction] option:selected").val() != val + 30

                        ) {
                            $("[id^=edit-annee-construction] option").eq(1).before($('<option>', {
                                value: val,
                                text: val,
                            }));   
                        }            
                    });

                    // PUISSANCE Ch - kW

                    $('[id^=edit-ch]').on('keyup keypress blur change', function() {
                        $('[id^=edit-kw]').val(Math.round(($(this).val())*0.735499));

                        if($('[id^=edit-ch]').val() == '') {
                            $('[id^=edit-kw]').val('');
                        }

                        if($('[id^=edit-kw]').val() == '') {
                            $('[id^=edit-ch]').val('');
                        }

                        if($('[id^=edit-ch]').val() == 0) {
                            $('[id^=edit-kw]').val('');
                        }

                        if($('[id^=edit-kw]').val() == 0) {
                            $('[id^=edit-ch]').val('');
                        }
                    });

                    function testFocus() {
                        if ($('[id^=edit-ch] ').is(":focus") || $('[id^=edit-kw]').is(":focus")) {
                            $('[id^=edit-puissance] .fieldset-wrapper').addClass('focus');
                        } else {
                            $('[id^=edit-puissance] .fieldset-wrapper').removeClass('focus');
                        }

                        event.stopPropagation();
                    }

                    $('[id^=edit-puissance] input').click(testFocus);
                    $('form').on('keydown keyup', testFocus);

                    $(document).click(function() {
                        if ($("[id^=edit-puissance] .fieldset-wrapper").hasClass("focus")) {
                            $('[id^=edit-puissance] .fieldset-wrapper').removeClass('focus');
                        }
                    });

                    $('[id^=edit-puissance] input').hover(function(){
                        if ($('[id^=edit-ch]').is(":hover") || $('[id^=edit-kw]').is(":hover")) {
                            $('[id^=edit-puissance] .fieldset-wrapper').addClass('hover');
                        } else {
                            $('[id^=edit-puissance] .fieldset-wrapper').removeClass('hover');
                        }
                    });

                    $('[id^=edit-ch]').change(function() {
                        $('#edit-kw').val(Math.round(($(this).val())*0.735499));

                        if($('#edit-ch').val() == '') {
                            $('[id^=edit-kw]').val('');
                        }

                        if($('[id^=edit-kw]').val() == '') {
                            $('#edit-ch').val('');
                        }
                    });

                    $('[id^=edit-kw]').on('keyup keypress blur', function() {
                        $('[id^=edit-ch]').val(Math.round(($(this).val())*1.35962));

                        if($('[id^=edit-ch]').val() == '') {
                            $('[id^=edit-kw]').val('');
                        }

                        if($('[id^=edit-kw]').val() == '') {
                            $('[id^=edit-ch]').val('');
                        }
                    });

                    $('[id^=edit-kw]').change(function() {
                        $('[id^=edit-ch]').val(Math.round(($(this).val())*1.35962));

                        if($('[id^=edit-ch]').val() == '') {
                            $('[id^=edit-kw]').val('');
                        }

                        if($('[id^=edit-kw]').val() == '') {
                            $('[id^=edit-ch]').val('');
                        }

                        if($('[id^=edit-ch]').val() == 0) {
                            $('[id^=edit-kw]').val('');
                        }

                        if($('[id^=edit-kw]').val() == 0) {
                            $('[id^=edit-ch]').val('');
                        }
                    });

                    if($('[id^=edit-puissance]').find('legend span').hasClass('form-required')) {
                        $('[id^=edit-puissance]').find('legend').addClass('form-required');
                    } else {
                        $('[id^=edit-puissance]').find('legend').removeClass('form-required');
                    }

                    $('[id^=edit-valeur]').attr('min','0');

                    // REQUIRED FIELD

                    $(".vehicule .required input").on("keydown keyup change",requiredField);
                    $(".vehicule select").on("keydown keyup change",requiredField);


                    // REQUIRED FILE

                    function requiredFile() {
                        if ($('.file').length) {
                            $('.vehicule .col-md-4.required').removeClass('required');
                            $('.vehicule .col-md-4.required-design').removeClass('required-design');
                            $(".vehicule .webform-button--next").addClass('active');
                            $(".vehicule .webform-button--next").css('pointer-events', 'all');


                        } else {
                            requiredField();
                        }
                    }

                    requiredFile();


                } else {
                    $('.webform-ajax-form-wrapper').removeClass('vehicule');
                }

                // GARANTIES 03
                if(attr == "edit-garanties") {
                    $('.webform-ajax-form-wrapper').removeClass('start');
                    $('.webform-ajax-form-wrapper').addClass('garantie');
                    $('body').addClass('grey-bg');

                    if($('.webform-ajax-form-wrapper .long-description').length > 0) {
                        $('.long-description .form-check > label').each(function (e) {
                            $(this).append('<span></span>');
                        });
                    }

                    $('.long-description .form-check .form-check-label').each(function (e) {
                        $('.long-description .description').insertAfter($(this).find('span'));
                    });

                    // REQUIRED CHECK

                    function requiredCheck() {
                        if($('.long-description input').is(':checked')) {
                            $(".garantie .webform-button--submit").addClass('active');
                            $(".garantie .webform-button--submit").css('pointer-events', 'all');
                        }

                        else {
                            $(".garantie .webform-button--submit").removeClass('active');
                            $(".garantie .webform-button--submit").css('pointer-events', 'none');
                            //return false
                        }
                    }

                    requiredCheck();

                    $('.garantie .long-description input').click(requiredCheck);


                    $('[id^=edit-formules-omnium-complte]').click(function(){
                       if($(this).is(':checked') && !$('[id^=edit-formules-omnium-partielle]').is(':checked')) {
                           $('[id^=edit-formules-omnium-partielle]').trigger('click');
                       }
                    });

                    $('[id^=edit-formules-omnium-complte]').on("change", function() {
                        if($('[id^=edit-formules-omnium-complte]').is(':checked')) {
                            $('[id^=edit-formules-omnium-partielle]').css('pointer-events', 'none');
                        } else {
                            $('[id^=edit-formules-omnium-partielle]').css('pointer-events', 'auto');
                        }
                    });

                } else {
                    $('.webform-ajax-form-wrapper').removeClass('garantie');
                    $('body').removeClass('grey-bg');
                }

                // START HABITATION 00
                if(attr == "edit-start-habitation") {
                    $('.webform-ajax-form-wrapper').addClass('start start-habitation');
                    $('body').addClass('grey-bg');

                    function startHabitation() {
                        if($('[id^=edit-type-habitation] input').is(':checked')) {
                            $(".start-habitation .webform-button--next").addClass('active');
                            $(".start-habitation .webform-button--next").css('pointer-events', 'all');
                        } else {
                            $(".start-habitation .webform-button--next").removeClass('active');
                            $(".start-habitation .webform-button--next").css('pointer-events', 'none');
                        }
                    }

                    startHabitation();

                    $('[id^=edit-type-habitation] input').click(startHabitation);
                } else {
                    $('.webform-ajax-form-wrapper').removeClass('start-habitation');
                    //$('body').removeClass('grey-bg');
                }

                // HABITATION 01
                if(attr == "edit-vos-informations") {
                    $('.webform-ajax-form-wrapper').removeClass('start');
                    $('.webform-ajax-form-wrapper').addClass('habitation-coordonnees');
                    $('[id^=edit-numero-d-agent]').attr('maxlength','4');

                    function requiredInfoHabitation() {
                        if ($('[id^=edit-prenom]').val() != ''
                            && $('[id^=edit-nom]').val() != ''
                            && $('[id^=edit-adresse-address]').val() != ''
                            && $('[id^=edit-adresse-postal]').val() != ''
                            && $('[id^=edit-adresse-city]').val() != ''
                            && $('[id^=edit-e-mail]').val() != ''
                            && $('[id^=edit-vous-etes--] input').is(':checked')
                            && (hasError == false)) {
                            $(".habitation-coordonnees .webform-button--next").addClass('active');
                            $(".habitation-coordonnees .webform-button--next").css('pointer-events', 'all');
                        } else {
                            $(".habitation-coordonnees .webform-button--next").removeClass('active');
                            $(".habitation-coordonnees .webform-button--next").css('pointer-events', 'none');
                        }
                    }

                    requiredInfoHabitation();

                    $(".habitation-coordonnees .required").on("keydown keyup change",requiredInfoHabitation);
                    //$(".coord select").on("keydown keyup change",requiredField);
                    $("body").on("change", '.habitation-coordonnees .js-webform-radios input',requiredInfoHabitation);
                } else {
                    $('.webform-ajax-form-wrapper').removeClass('habitation-coordonnees');
                }

                // HABITATION 02
                if(attr == "edit-votre-maison") {
                    $('.webform-ajax-form-wrapper').removeClass('start');
                    $('.webform-ajax-form-wrapper').addClass('habitation-maison');

                    $(".inline-input label").each(function(index) {
                        if($(this).next("small").length) {
                            $(this).next("small").addBack().wrapAll("<div class='description-element-wrapper' />")
                        }
                    });

                    $(".inline-radio legend").each(function(index) {
                        if($(this).parent().find("small").length) {
                            $(this).parent().find("small").unwrap();
                            $(this).next("small").addBack().wrapAll("<div class='description-element-wrapper' />")
                        }
                    });

                    // TOTAL ROOMS
                    var roomVal;
                    var otherRoomVal;
                    var garageVal;

                    $('[id^=edit-container-piece-wrapper] select option:selected').each(function(){
                       if($(this).val() == 'empty_option') {
                           $(this).val(0);
                       }
                    });

                    //$('.js-form-item-chambre-coucher select option:checked').val(0);
                    //$('.js-form-item-autre-pieces select option:checked').val(0);
                    //$('.js-form-item-garage select option:checked').val(0);

                    function totalRooms() {
                        $('.js-form-item-total input').val('');

                            roomVal = parseInt($('.js-form-item-chambre-coucher select option:selected').val());

                            otherRoomVal = parseInt($('.js-form-item-autre-pieces select option:selected').val());

                            garageVal = parseInt($('.js-form-item-garage select option:selected').val());

                            $('[id^=edit-total]').val(roomVal + otherRoomVal + garageVal + 1);

                            if(roomVal < 19) {
                                $('.js-form-type-range input').val((roomVal * 15000) + 25000);
                                $("output").val((roomVal * 15000) + 25000 + '€');
                            }
                    }

                    totalRooms();

                    $('[id^=edit-container-piece] select').on('keyup keypress blur change', totalRooms);
                    $('.js-form-item-chambre-coucher select').on('keyup keypress blur change', function(){
                        $('.js-form-type-range output').removeClass('checked');
                    });

                    // RANGE
                    $('.js-form-type-range label').next("small").addBack().wrapAll("<div class='range-label-wrapper' />");
                    $('.js-form-type-range input').next("output").addBack().wrapAll("<div class='range-input-wrapper' />");

                    /* FORM RANGE */
                    function rangeChange() {
                        $("output").addClass('checked');

                        if($("output").hasClass('big')) {
                            $("output").removeClass('big');
                        }
                    }

                    $(".form-range")
                        .mousedown(function() {
                            if(!$("output").hasClass('big')) {
                                $("output").addClass('big');
                            }
                        })

                        .mousemove(function() {

                        })

                        .mouseup(rangeChange);

                    // VALIDATE
                    function validateMaison() {
                        if($('.habitation-maison .js-form-item-nombre-facade select option:selected').val() != 'empty_option') {
                            $('.habitation-maison .js-form-item-nombre-facade select').addClass('focus');

                        } else {
                            $('.habitation-maison .js-form-item-nombre-facade select').removeClass('focus');
                        }

                        if($('.habitation-maison .js-form-item-type-construction select option:selected').val() != 'empty_option') {
                            $('.habitation-maison .js-form-item-type-construction select').addClass('focus');

                        } else {
                            $('.habitation-maison .js-form-item-type-construction select').removeClass('focus');
                        }

                        if($('.habitation-maison .js-form-item-age-construction select option:selected').val() != 'empty_option') {
                            $('.habitation-maison .js-form-item-age-construction select').addClass('focus');

                        } else {
                            $('.habitation-maison .js-form-item-age-construction select').removeClass('focus');
                        }

                        if($('[id^=edit-container-radio-00]').hasClass('js-webform-states-hidden')) {
                            if ($('[id^=edit-assurance-vol] input').is(':checked')
                                && $('[id^=edit-assurance-rc] input').is(':checked')
                                && $('.long-description input').is(':checked')) {
                                $(".habitation-maison .webform-button--submit").addClass('active');
                                $(".habitation-maison .webform-button--submit").css('pointer-events', 'all');
                                //console.log('tutu');
                            } else {
                                $(".habitation-maison .webform-button--submit").removeClass('active');
                                $(".habitation-maison .webform-button--submit").css('pointer-events', 'none');
                            }
                        } else {
                            if(($('[id^=edit-recours]').length > 0) && (!$('[id^=edit-container-recours]').hasClass('js-webform-states-hidden'))) {
                                if (
                                    $('[id^=edit-nombre-facade] option:selected').val() != 'empty_option'
                                    && $('[id^=edit-type-construction] option:selected').val() != 'empty_option'
                                    && $('[id^=edit-age-construction] option:selected').val() != 'empty_option'
                                    && $('[id^=edit-assurance-vol] input').is(':checked')
                                    && $('[id^=edit-assurance-rc] input').is(':checked')
                                    && $('[id^=edit-visite] input').is(':checked')
                                    && $('[id^=edit-recours] input').is(':checked')
                                    && $('.long-description input').is(':checked')) {
                                    $(".habitation-maison .webform-button--submit").addClass('active');
                                    $(".habitation-maison .webform-button--submit").css('pointer-events', 'all');
                                } else {
                                    $(".habitation-maison .webform-button--submit").removeClass('active');
                                    $(".habitation-maison .webform-button--submit").css('pointer-events', 'none');
                                }
                            } else if($('[id^=edit-visite]').length > 0) {
                                if (
                                    $('[id^=edit-nombre-facade] option:selected').val() != 'empty_option'
                                    && $('[id^=edit-type-construction] option:selected').val() != 'empty_option'
                                    && $('[id^=edit-age-construction] option:selected').val() != 'empty_option'
                                    && $('[id^=edit-assurance-vol] input').is(':checked')
                                    && $('[id^=edit-assurance-rc] input').is(':checked')
                                    && $('[id^=edit-visite] input').is(':checked')
                                    && $('.long-description input').is(':checked')) {
                                    $(".habitation-maison .webform-button--submit").addClass('active');
                                    $(".habitation-maison .webform-button--submit").css('pointer-events', 'all');
                                } else {
                                    $(".habitation-maison .webform-button--submit").removeClass('active');
                                    $(".habitation-maison .webform-button--submit").css('pointer-events', 'none');
                                }
                            } else {
                                if (
                                    $('[id^=edit-nombre-facade] option:selected').val() != 'empty_option'
                                    && $('[id^=edit-type-construction] option:selected').val() != 'empty_option'
                                    && $('[id^=edit-age-construction] option:selected').val() != 'empty_option'
                                    && $('[id^=edit-assurance-vol] input').is(':checked')
                                    && $('[id^=edit-assurance-rc] input').is(':checked')
                                    && $('.long-description input').is(':checked')) {
                                    $(".habitation-maison .webform-button--submit").addClass('active');
                                    $(".habitation-maison .webform-button--submit").css('pointer-events', 'all');
                                } else {
                                    $(".habitation-maison .webform-button--submit").removeClass('active');
                                    $(".habitation-maison .webform-button--submit").css('pointer-events', 'none');
                                }
                            }
                        }
                    }

                    validateMaison();

                    $('body').on("change", '.habitation-maison select', validateMaison);
                    $('.habitation-maison .webform-type-radios input').on("change", validateMaison);
                    $('.habitation-maison .long-description input').click(validateMaison);

                    if($('.webform-ajax-form-wrapper .long-description').length > 0) {
                        $('.long-description .form-check > label').each(function (e) {
                            $(this).append('<span></span>');
                        });
                    }

                    $('.long-description .form-check .form-check-label').each(function (e) {
                        $('.long-description .description').insertAfter($(this).find('span'));
                    });
                } else {
                    $('.webform-ajax-form-wrapper').removeClass('habitation-maison');
                }


                // OTHER STEPS
                if($('.confirmation-step').length) {
                    $('.webform-ajax-form-wrapper').addClass('confirmation-wrapper');
                    $('.webform-ajax-form-wrapper').removeClass('start');
                } else {
                    $('.webform-ajax-form-wrapper').removeClass('confirmation-wrapper');
                }

                // THANK YOU
                if($('.webform-confirmation__message').length) {
                    $('.webform-progress-tracker').css('display', 'none');
                    $('.webform-ajax-form-wrapper').css({'padding-top':'0px', 'padding-bottom':'0px'});
                }

                // ADD NO SCROLL
                if($('.confirmation-step').length || attr == "edit-start-auto" || attr == "edit-start-habitation") {
                    $('body').addClass('no-scroll');
                    $('body').addClass('grey-bg');
                } else {
                    $('body').removeClass('no-scroll');
                }
            }
        }
    }
});


